import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { DrcButton, DrcLanguageHeaderButton } from '@driscollsinc/driscolls-react-components';
import DirectionsRun from '@material-ui/icons/DirectionsRun';

var styles = () => ({
    headerButtons: {
        float: 'right',
        minWidth: 0,
        border: 'none !important',
        margin: 4,
        '& svg': {
            fontSize: 24
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 700,
            width: '100%'
        }
    },
    langPopover: {
        '& .MuiPaper-elevation8': {
            padding: '4px 12px',
            maxWidth: 400,
            width: '100%'
        }
    },
    language: {
        width: 120,
        float: 'right',
        height: 22
    },
    languageBtn: {
        width: '100%',
        margin: '8px 0'
    }
});

class HeaderButtons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            loggedInAs: '',
            anchorEl: null,
            anchorElLanguage: null
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.props.authState ? (
                    <React.Fragment>
                        <DrcButton className={classes.headerButtons} noStyle to="/LogOut/">
                            <DirectionsRun />
                        </DrcButton>
                    </React.Fragment>
                ) : null}
                <DrcLanguageHeaderButton />
            </React.Fragment>
        );
    }
}

export default withOktaAuth(withRouter(withStyles(styles)(HeaderButtons)));
