// [English, Spanish, Chinese, French]
/* eslint-disable */
const APP_TRANSLATIONS = {
    berries: {
        black: ['Blackberry', 'Mora', '黑莓', 'la mûre'],
        blacks: ['Blackberries', 'Moras', '黑莓', 'Mûres'],
        blue: ['Blueberry', 'Arándano', '蓝莓', 'Myrtille'],
        blues: ['Blueberries', 'Arándanos', '蓝莓', 'Myrtilles'],
        rasp: ['Raspberry', 'Framboise', '覆盆子', 'Framboise'],
        rasps: ['Raspberries', 'Frambuesas', '山莓', 'Framboises'],
        straw: ['Strawberry', 'Fresa', '草莓', 'Fraise'],
        straws: ['Strawberries', 'Fresas', '草莓', 'Des fraises']
    },
    common: {
        yes: ['Yes', 'Sí', '是', 'Oui'],
        no: ['No', 'No', '否', 'Non']
    },
    general: {
        trace: ['Trace', 'Rastrear', '跟踪', 'Trace'],
        clickToScan: ['Click to Scan', 'Haga clic para escanear', '点击扫描', 'Cliquez pour numériser'],
        readyToScan: ['Ready to Scan', 'Listo para escanear', '准备扫描', 'Prêt à numériser'],
        scanning: ['Scanning', 'Escaneo', '扫描', 'Balayage'],
        details: ['Details', 'Detalles', '细节', 'Des détails'],
        loadingTitle: ['Loading', 'Cargando', '载入', 'Chargement'],
        loadingDetails: ['Gathering Information', 'Cargando detalles', '加载详情', 'Chargement des détails'],
        error: ['Unknown error has occurred', 'Ha ocurrido un error desconocido', '发生未知错误', "Une erreur inconnue s'est produite"],
        noData: ['No Data Found', 'Datos no encontrados', '没有找到数据', 'Aucune donnée disponible'],
        noLocation: ['No Location Found', 'No se encontró ninguna ubicación', '未找到位置', 'Aucun emplacement trouvé'],
        newVersionHeader: ['New Version Available'],
        newVersionBody: ['Would you like to refresh to view the latest update?'],
        reports: ['Reports', 'Reportes', '报告', 'Rapports'],
        search: ['Search', 'Buscar', '搜索', 'Rechercher'],
        errorTitle: ['Error', 'Error', '错误', 'Erreur'],
        badFormat: ['Format is Invalid', 'Formato inválido', '格式无效', 'Format invalide']
    },
    details: {
        Clamshell: ['Clam', 'Clam', '箱体', 'Clam'],
        production: ['Production', 'Producción', '生产', 'Production'],
        PackDate: ['Pack Date', 'Fecha de empaque', '包装日期', "Date d'emballage"],
        ProducingArea: ['Producing Area', 'Área de producción', '产区', 'Zone de production'],
        ProducingAreaNbr: ['Producing Area Number', 'Número de área de producción', '产区编号', 'Numéro de zone de production'],
        ProducingAreaName: ['Producing Area Name', 'Nombre de área de producción', '产区名称', 'Nom de zone de production'],
        GrowerNbr: ['Grower #', 'Productor #', '种植者#', 'Producteur #'],
        GrowerName: ['Grower Name', 'Nombre del productor', '种植者姓名', 'Nom du producteur'],
        RanchNbr: ['Ranch #', 'Rancho #', '牧场#', '# de ranch'],
        RanchName: ['Ranch Name', 'Nombre del rancho', '牧场名称', 'Nom du ranch'],
        item: ['Item', 'Artículo', '物品', 'Article'],
        Commodity: ['Berry Type', 'Tipo de baya', '浆果型', 'Type de baie'],
        VarietyCode: ['Variety Code', 'Código de variedad', '品种代码', 'Code de la variété'],
        VarietyName: ['Variety Name', 'Nombre de la variedad', '品种名称', 'Nom de la variété'],
        ItemNbr: ['Item #', 'Artículo #', '物品＃', 'Article #'],
        ItemName: ['Item Description', 'Descripción del Artículo', '商品描述', "Description de l'article"],
        ItemDescription: ['Item Description', 'Descripción del Artículo', '商品描述', "Description de l'article"],
        ApplicationId: ['App ID', 'ID de aplicación', '应用程序 ID', "Identifiant de l'application"],
        TreatmentMonth: ['Treatment Month', 'Mes de tratamiento', '治疗月', 'Mois de traitement'],
        TreatmentTiming: ['Treatment Timing', 'Tiempo de tratamiento', '治疗时间', 'Moment du traitement'],
        TreatmentType: ['Treatment Type', 'Tipo de tratamiento', '治疗类型', 'Type de traitement'],
        Location: ['Location', 'Localización', '地点', 'Emplacement'],
        ranchLocation: ['Ranch Location', 'Ubicación del rancho', '牧场位置', 'Emplacement du ranch'],
        HarvestOrder: ['Harvest Order', 'Orden de cosecha', '收获顺序', 'Ordre de récolte'],
        PTI: ['PTI', 'PTI', 'PTI', 'PTI'],
        labelInfo: ['Label Info', 'Información de etiqueta', '标签信息', 'Informations sur le label'],
        CreatedBy: ['Created By', 'Creado por', '创建者', 'Créé par'],
        CreatedDateTime: ['Created Date Time', 'Fecha y hora de creación', '创建日期时间', 'Date et heure de création'],
        QtyOrdered: ['Qty Ordered', 'Cantidad ordenada', '订购数量', 'Quantité commandée'],
        LabelQuantity: ['Labels Printed', 'Etiquetas impresas', '标签打印', 'Étiquettes imprimées'],
        LabelSize: ['Label Size', 'Tamaño de etiqueta', '标签大小', 'Taille du label'],
        BatchNumber: ['Batch Number', 'Número de lote', '批次号', 'Numéro de lot'],
        LabelIndex: ['Label Index', 'Índice de etiqueta', '标签索引', 'Index d\'étiquette'],
        summaryLabel: ['Summary', 'Resumen', '摘要', 'Résumé'],
        PrinterName: ['Printer Name', 'Nombre de impresora', '打印机名称', 'Nom de l\'imprimante'],
        IsPrinterDriscolls : ['Is Printer Driscoll\'s', 'Es impresora Driscoll\'s', '是否是Driscoll\'s的打印机', 'Est-ce une imprimante Driscoll\'s'],
    },
    reports: {
        ptiTitle: ['PTI', 'PTI', 'PTI', 'PTI'],
        ptiDescription: ['Produce Traceability Initiative Report on Label Data', 'Producir informe de la iniciativa de trazabilidad sobre datos de etiquetas', '生产PTI标签数据报告', 'Produire le rapport de PTI sur les données d\'étiquette'],
        ptiPageTitle: ['PTI Report', 'Informe de PTI', 'PTI报告', 'Rapport PTI'],
    },
    pti: {
        dateRange: ['Date Range', 'Rango de fechas', '日期范围', 'Période'],
        groupByCommodity: ['Group by Commodity', 'Agrupar por tipo de baya', '分组商品', 'Grouper par type de baya'],
        groupByIsReprint: ['Group by Reprint', 'Agrupar por repetición', '分组重印', 'Grouper par réimpression'],
        groupByIsHarvestOrder: ['Group by Harvest Order', 'Agrupar por orden de cosecha', '分组收获顺序', 'Grouper par ordre de récolte'],
        BLACK: ['Blackberry', 'Mora', '黑莓', 'la mûre'],
        BLUE: ['Blueberry', 'Arándano', '蓝莓', 'Myrtille'],
        RASP: ['Raspberry', 'Framboise', '覆盆子', 'Framboise'],
        STRAW: ['Strawberry', 'Fresa', '草莓', 'Fraise'],
        Total: ['Total', 'Total', '总计', 'Total'],
    }
};

export default APP_TRANSLATIONS;
