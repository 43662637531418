import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setPageTitleAction, setUserRoleName } from '../actions/actions';

const pageTitle = 'Initializing';

class InitializeApplication extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //TODO: Need way to turn off header navigation so we can stay here till we have the master data
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    render() {
        const { redirectUrl } = this.props;

        return <Redirect to={redirectUrl} />;
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        redirectUrl: state.masterReducer.redirectUrl
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setUserRoleName: (data) => dispatch(setUserRoleName(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(InitializeApplication);
