import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Translate } from 'react-localize-redux';
import { Helmet, DrcImage, DrcButton, DrcIconButton } from '@driscollsinc/driscolls-react-components';
import { Zoom } from 'react-slideshow-image';
import FancyBlackPng from '../Images/Fancy_Black.png';
import FancyBlackWebP from '../Images/Fancy_Black.webp';
import FancyBluePng from '../Images/Fancy_Blue.png';
import FancyBlueWebP from '../Images/Fancy_Blue.webp';
import FancyRaspPng from '../Images/Fancy_Rasp.png';
import FancyRaspWebP from '../Images/Fancy_Rasp.webp';
import FancyStrawPng from '../Images/Fancy_Straw.png';
import FancyStrawWebP from '../Images/Fancy_Straw.webp';
import 'react-slideshow-image/dist/styles.css';

const useStyles = makeStyles({
    main: {
        width: '400px !important',
        maxWidth: '70vw',
        height: 400,
        maxHeight: '70vh',
        borderRadius: '200px !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    title: {
        fontFamily: 'BlogScript',
        fontSize: '3rem',
        fontWeight: 'normal',
        margin: 0,
        textShadow: '2px 2px 6px #fff',
        textAlign: 'center',
        '@media (prefers-color-scheme: dark)': {
            textShadow: '2px 2px 6px #000'
        }
    },
    backgroundImage: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -5,
        '& .zoom-wrapper': {
            overflow: 'visible'
        }
    },
    loader: {
        width: 100,
        height: 180,
        position: 'absolute',
        left: '50%',
        top: '75%',
        transform: 'translate(-50%,-50%) rotate(90deg)',
        backfaceVisibility: 'hidden',
        boxShadow: '0px 0px 12px 0px #fff6',
        backgroundColor: '#fff6',
        borderRadius: 20,
        '& span': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: 20,
            backgroundColor: '#49773d99',
            zIndex: 1,
            transform: 'translateY(0%)',
            animation: '$move 1s linear',
            animationIterationCount: 'infinite',
            borderRadius: 10
        },
        '& > div': {
            zIndex: 1,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            backfaceVisibility: 'hidden'
        },
        '& i': {
            display: 'block',
            height: 1,
            background: '#000',
            margin: '0 auto 2.2px',
            backfaceVisibility: 'hidden',
            '&:nth-child(2)': { width: '75%' },
            '&:nth-child(3)': { width: '81%' },
            '&:nth-child(4)': { width: '87%' },
            '&:nth-child(6)': { width: '71%' },
            '&:nth-child(7)': { width: '81%' },
            '&:nth-child(8)': { width: '65%' },
            '&:nth-child(9)': { width: '83%' },
            '&:nth-child(10)': { width: '75%' },
            '&:nth-child(12)': { width: '86%' },
            '&:nth-child(14)': { width: '65%' },
            '&:nth-child(16)': { width: '75%' },
            '&:nth-child(18)': { width: '83%' },
            '&:nth-child(20)': { width: '75%' },
            '&:nth-child(23)': { width: '81%' },
            '&:nth-child(24)': { width: '87%' },
            '&:nth-child(26)': { width: '71%' },
            '&:nth-child(27)': { width: '81%' },
            '&:nth-child(28)': { width: '65%' },
            '&:nth-child(29)': { width: '83%' },
            '&:nth-child(30)': { width: '75%' },
            '&:nth-child(32)': { width: '86%' },
            '&:nth-child(34)': { width: '65%' },
            '&:nth-child(36)': { width: '75%' },
            '&:nth-child(38)': { width: '83%' }
        },
        '&:before, &:after, & em:after, & em:before': {
            borderColor: '#000',
            content: '',
            position: 'absolute',
            width: 20,
            height: 20,
            borderStyle: 'solid',
            borderWidth: 0
        },
        '&:before': {
            left: 0,
            top: 0,
            borderLeftWidth: 1,
            borderTopWidth: 1
        },
        '&:after': {
            right: 0,
            top: 0,
            borderRightWidth: 1,
            borderTopWidth: 1
        },
        '& em:before': {
            left: 0,
            bottom: 0,
            borderLeftWidth: 1,
            borderBottomWidth: 1
        },
        '& em:after': {
            right: 0,
            bottom: 0,
            borderRightWidth: 1,
            borderBottomWidth: 1
        }
    },
    '@keyframes move': {
        '0%, 100%': { transform: 'translateY(400%)' },
        '50%': { transform: 'translateY(0%)' },
        '75%': { transform: 'translateY(800%)' }
    },
    reportBtn: {
        bottom: 0,
        position: 'absolute',
        left: 'calc(50vw - 50px)',
        width: 100
    }
});

function ReadyToScan() {
    const classes = useStyles();

    return (
        <>
            <Zoom
                cssClass={classes.backgroundImage}
                arrows={false}
                pauseOnHover={false}
                canSwipe={false}
                easing="ease-in"
                scale={1.4}
                transitionDuration={5000}
                duration={0}
            >
                <DrcImage src={FancyBlackPng} webp={FancyBlackWebP} className={classes.backgroundImage} alt="" />
                <DrcImage src={FancyBluePng} webp={FancyBlueWebP} className={classes.backgroundImage} alt="" />
                <DrcImage src={FancyRaspPng} webp={FancyRaspWebP} className={classes.backgroundImage} alt="" />
                <DrcImage src={FancyStrawPng} webp={FancyStrawWebP} className={classes.backgroundImage} alt="" />
            </Zoom>
            <Translate>
                {({ translate }) => (
                    <>
                        <Helmet>
                            <title>
                                {translate('general.trace', null, { renderInnerHtml: false })}
                                {' :: '}
                                {translate('general.readyToScan', null, { renderInnerHtml: false })}
                            </title>
                        </Helmet>
                        <DrcButton className={classes.main} isPrimary to="/Scanning/">
                            <h1 className={classes.title}>
                                <Translate id="general.clickToScan" />
                            </h1>
                            <div className={classes.loader}>
                                <em></em>
                                <div>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                </div>
                                <span></span>
                            </div>
                        </DrcButton>
                        <DrcIconButton className={classes.reportBtn} to='/Reports/'>
                            <i className="pi pi-book"></i>&nbsp;{translate('general.reports', null, { renderInnerHtml: false })}
                        </DrcIconButton>
                    </>
                )}
            </Translate>
        </>
    );
}

export default ReadyToScan;
