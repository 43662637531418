import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { withLocalize, Translate } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcImage,
    DrcPageNotFound,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcMain,
    DrcPanel,
    DrcMediaQueries,
    Helmet,
    DrcVersionInfo,
    DrcPageLogin,
    DrcImplicitCallback,
    DrcLoading,
    DrcTranslations,
    DrcPwaInstallHelper
} from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities, DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import {
    hideCommonDialogAction,
    showLoadingScreenAction,
    hideLoadingScreenAction,
    addInfo,
    setCommonDialogAction,
    showToast,
    serviceWorkerUpdate
} from './actions/actions';
import { setInitializeRedirectUrl } from './actions/MasterActions';
import LoggingUtilities from './data/LoggingUtilities';
import HeaderButtons from './components/HeaderButtons';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
import APP_TRANSLATIONS from './data/appTranslations';
import { createBrowserHistory } from 'history';
import LogoPng from './Images/Logo_Small_Transparent.png';
import LogoWebP from './Images/Logo_Small_Transparent.webp';
import BackgroundPng from './Images/Bowl_Mixed_3.png';
import BackgroundWebP from './Images/Bowl_Mixed_3.webp';

import ReadyToScan from './pages/ReadyToScan';

const Scanning = lazy(() => import('./pages/Scanning'));
const Details = lazy(() => import('./pages/Details'));
const Releases = lazy(() => import('./pages/Releases'));
const Reports = lazy(() => import('./pages/Reports'));
const PTIReport = lazy(() => import('./pages/Reports/PTIReport'));

const allGroups = window.config.ALL_GROUPS || [];
const AUDIT_REPORT_GROUPS = window.config.AUDIT_REPORT_GROUPS || [];

var styles = (theme) => ({
    '@global': {
        '::-webkit-scrollbar': {
            /* width */ width: '10px',
            height: '10px'
        },
        '::-webkit-scrollbar-track': {
            /* Track */ boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb': {
            /* Handle */ backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        },
        '.MuiButton-root': {
            fontSize: '1rem',
            boxShadow: 'none'
        }
    },
    logoLink: {
        left: 'calc(50vw - 70px)',
        position: 'absolute'
    },
    logo: {
        height: 40,
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    logoShowAlways: {
        height: 40
    },
    header: {
        background: 'transparent',
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 97),
        '& .MuiToolbar-root': {
            padding: '0px 12px'
        },
        '& .titleContainer h1': {
            display: 'inline-block'
        },
        '& .titleContainer button, & .titleContainer a': {
            marginTop: 16,
            ['@media ' + DrcMediaQueries.mobileL]: {
                marginTop: 8
            }
        },
        '& .title': {
            textShadow: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsla(341, 57%, 15%, 1)',
            '& .title': {
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        },
        '@media print': {
            display: 'none'
        }
    },
    loaderWithMessage: {
        width: '644px',
        left: 'calc(50vw - 322px)',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: '#000',
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#ddd'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: {
                ...DuCommonTranslations.COMMON_TRANSLATIONS,
                ...DrcTranslations,
                ...APP_TRANSLATIONS
            },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/Scan/');

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Polymorphism);
    }

    closeCommonDialog() {
        this.props.hideCommonDialog();
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        var { classes } = this.props;

        return (
            <DrcThemeProvider theme={this.state.theme}>
                <Translate>
                    {({ translate }) => (
                        <>
                            <DrcEnvironmentMarker />
                            <Helmet>
                                <title>{translate('general.trace', null, { renderInnerHtml: false })}</title>
                            </Helmet>
                            <Router history={Router.browserHistory}>
                                <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                                    <DrcSecureHeader
                                        title={<Translate id="general.trace" />}
                                        allLinks={[]}
                                        fullWidth={true}
                                        logo={
                                            <Link to="/Scan/" className={classes.logoLink}>
                                                <DrcImage src={LogoPng} webp={LogoWebP} className={classes.logoShowAlways} alt="Driscoll's Logo" />
                                            </Link>
                                        }
                                        className={classes.header}
                                    >
                                        <HeaderButtons />
                                    </DrcSecureHeader>
                                    <Suspense
                                        fallback={
                                            <DrcMain transparent>
                                                <DrcPanel>
                                                    <DrcLoading />
                                                </DrcPanel>
                                            </DrcMain>
                                        }
                                    >
                                        <Switch>
                                            <Route
                                                path="/"
                                                exact
                                                render={(props) => (
                                                    <DrcPageLogin {...props} poly backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
                                                )}
                                            />
                                            <DrcSecureGroupRoute
                                                path="/InitializeApplication/"
                                                exact
                                                component={InitializeApplication}
                                                groupsAllowed={allGroups}
                                            />
                                            <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={allGroups} />
                                            <DrcSecureGroupRoute path="/Scan/" exact component={ReadyToScan} groupsAllowed={allGroups} />
                                            <DrcSecureGroupRoute path="/Scanning/" exact component={Scanning} groupsAllowed={allGroups} />
                                            <DrcSecureGroupRoute path="/Details/:type/:value/:id/" exact component={Details} groupsAllowed={allGroups} />
                                            <DrcSecureGroupRoute path="/Reports/" exact component={Reports} groupsAllowed={AUDIT_REPORT_GROUPS} />
                                            <DrcSecureGroupRoute path="/Reports/PTI/:Settings/" exact component={PTIReport} groupsAllowed={AUDIT_REPORT_GROUPS} />
                                            <Route
                                                path="/implicit/callback"
                                                render={(props) => (
                                                    <DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
                                                )}
                                            />
                                            <Route path="/LogOut/" exact component={LogOut} />
                                            <Route component={DrcPageNotFound} />
                                        </Switch>
                                    </Suspense>
                                </Security>
                                <DrcVersionInfo allowClick={this.props.isInitialized} />
                                <DrcPwaInstallHelper />
                                <DrcDialog
                                    isError={this.props.commonDialog.isMessage}
                                    title={this.props.commonDialog.title}
                                    open={this.props.commonDialog.show}
                                    buttons={
                                        <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                            {translate('buttons.okay', null, { renderInnerHtml: false })}
                                        </DrcButton>
                                    }
                                >
                                    {this.props.commonDialog.content}
                                </DrcDialog>
                                <DrcDialog
                                    title={translate('general.newVersionHeader', null, { renderInnerHtml: false })}
                                    open={this.props.serviceWorkerUpdated}
                                    buttons={
                                        <>
                                            <DrcButton onClick={this.closeNewVersionDialog}>
                                                {translate('buttons.no', null, { renderInnerHtml: false })}
                                            </DrcButton>
                                            <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                                {translate('buttons.yes', null, { renderInnerHtml: false })}
                                            </DrcButton>
                                        </>
                                    }
                                >
                                    <div>{translate('general.newVersionBody', null, { renderInnerHtml: false })}</div>
                                </DrcDialog>
                            </Router>
                            {this.props.toastMessage && (
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                    className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                                    open={this.props.toastMessage !== ''}
                                    autoHideDuration={3000}
                                    onClose={this.handleCloseNotification}
                                    message={<span id="message-id">{this.props.toastMessage}</span>}
                                />
                            )}
                        </>
                    )}
                </Translate>
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonDialog: state.rootReducer.commonDialog,
        pageShortTitle: state.rootReducer.pageShortTitle,
        isInitialized: state.masterReducer.isInitialized,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
